
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    
    @Component
    export default class SetScenarioName extends Vue {

        private modalShow = false;

        @Prop({ default: null }) public scenario: any;
        
        @Watch('scenario')
        private onScenarioChanged(value: any) {
            if (value) {
                this.modalShow = true;
            }
            else this.modalShow = false;
        }

        private submit() {
            // @ts-ignore:
            const valid = this.$refs.form.validate()
            if (valid) this.scenario['save'] = true;
        }

        private valid = true;

        private nameRules = [
            // @ts-ignore
            value => !!value || this.$t("message.scenarioNameIsRequired")
        ]
    }
