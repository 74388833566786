
    import { Component, Vue, Prop } from 'vue-property-decorator';
    
    @Component({
        components: {
        },
    })
    export default class MapLegend extends Vue {
        @Prop({ default: null }) public data: any;

        private getBrightness(r: number, g: number, b: number): number {
            // Convert the RGB values to the range [0, 1]
            const rNorm = r / 255;
            const gNorm = g / 255;
            const bNorm = b / 255;

            // Apply the luminance formula
            const luminance = 0.2126 * rNorm + 0.7152 * gNorm + 0.0722 * bNorm;

            return luminance;
        }

        // Function to determine if the text should be dark or light
        private getTextColorForBackground(backgroundColor: string) {
            let r: number, g: number, b: number;

            // If backgroundColor is in hex format, convert to RGB
            if (backgroundColor.startsWith('#')) {
                const hex = backgroundColor.replace('#', '');
                r = parseInt(hex.substring(0, 2), 16);
                g = parseInt(hex.substring(2, 4), 16);
                b = parseInt(hex.substring(4, 6), 16);
            } else if (backgroundColor.startsWith('rgb')) {
                // Extract RGB values from the string in the format rgb(r, g, b)
                const rgb = backgroundColor.match(/\d+/g);
                if (rgb) {
                    r = parseInt(rgb[0], 10);
                    g = parseInt(rgb[1], 10);
                    b = parseInt(rgb[2], 10);
                } else {
                    throw new Error("Invalid RGB color format");
                }
            } else {
                throw new Error("Invalid color format");
            }

            const brightness = this.getBrightness(r, g, b);

            // If brightness is greater than 0.5, return dark text, otherwise return light text
            return brightness > 0.5 ? '#333333' : '#dddddd';
        }
        
    }
