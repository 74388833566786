

    import { Action, Getter } from 'vuex-class';
    import { Component, Vue, Watch } from 'vue-property-decorator';


    const namespace = 'admin';

    @Component({
        components: {
        },
    })
    export default class AdminUsers extends Vue {

        @Getter('getModels', { namespace }) getModels: any;
        @Getter('getIsLoggedIn') getIsLoggedIn: any;
        @Action('fetchModels', { namespace }) fetchModels: any;
        @Action('setSelectedModel', { namespace }) setSelectedModel: any;

        private filter = '';


        @Watch('getIsLoggedIn')
        private onGetIsLoggedInChanged() {
            this.fetchModels();
        }
        mounted() {
            if (this.getIsLoggedIn) this.onGetIsLoggedInChanged();
        }

        get filteredModels() {
            const _self = this;
            const _filteredModels = this.getModels.filter(function (model: any) {
                return _self.filter == '' || _self.filter == null || model.name.toLowerCase().includes(_self.filter.toLowerCase());
            });

            return _filteredModels;
        }

    }

