
    import { Component, Vue } from 'vue-property-decorator';
    import { Getter } from 'vuex-class';
    import Error from '@/components/Error.vue';
    // @ts-ignore
    import Loading from 'vue-full-loading';
    @Component({
        components: {
            Error,
            Loading
        },
    })
    export default class App extends Vue {
        
        @Getter('getBusy') busy!: number;
        @Getter('getDatabaseStatus') databaseStatus!: number;
        @Getter('getError') error!: any;
        @Getter('getEnvironment') environment!: any;
        @Getter('getVersion') version!: any;
        @Getter('getInProgress') inProgress!: number;
        
        updateStatus(disabled: any) {
            alert(disabled);
        }

        
        

    }
