
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    

    @Component
    export default class CloseLocation extends Vue {


        private modalShow = false;

        @Prop({ default: null }) public location: any;
        @Prop({ default: null }) public model: any;
        @Prop({ default: null }) public scenario: any;

        mounted() {
            this.$on('escKey' + this.$options.name, () => {
                if (this.modalShow) this.cancel();
            });
            document.addEventListener("keydown", (e) => {
                if (e.keyCode == 27) {
                    this.$emit('escKey' + this.$options.name);
                }
            });
        }

        private valid = false;

        @Watch('location')
        private onLocationChanged(value: any) {
            if (value) this.modalShow = true; else this.modalShow = false;
        }

        private save(scenarioCreation: any) {
            const fieldsToValidate = []; // List of your ref names
            this.$refs.form.validate()
            fieldsToValidate.forEach((fieldRef) => {
            const field = this.$refs[fieldRef];
                if (field && field.validate) {
                    const isValid = field.validate();
                    if (!isValid) field.$el.scrollIntoView({ behavior: 'smooth' });
                }
            });
            if (this.valid) {
                this.location['scenarioCreation'] = scenarioCreation;
                this.$emit('save', this.location );
            }
        }

        private async cancel() {
            this.modalShow = false;
        }

        private menu = false;

        private toggleMenu() {
            this.menu = !this.menu;
        }
    }
