

    import { Action, Getter } from 'vuex-class';
    import { Component, Vue } from 'vue-property-decorator';


    const namespace = 'admin';

    @Component({
        components: {
        },
    })
    export default class AdminUsers extends Vue {



       
        @Getter('getSelectedScenario', { namespace }) getSelectedScenario: any;
        
        @Action('setSelectedScenario', { namespace }) setSelectedScenario: any;
        

        @Action('calculateUnprocessedScenarios', { namespace }) calculateUnprocessedScenarios: any;
        



    }

