
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    
    
    @Component({
        name: 'CopyChanges',
        components: {
            
        },
    })
    export default class CopyChanges extends Vue {

       

        private modalShow = false;

       

        @Prop({ default: null }) public scenario: any;
        @Prop({ default: null }) public model: any;
        @Prop({ default: null }) public scenarios: any;


        @Watch('scenario')
        private onScenarioChanged(value: any) {
            var self = this;
            if (value) {
                self.scenario.selectedLocations = [];
                this.scenario.changedLocations.forEach((location : any) => {
                    self.scenario.selectedLocations.push(location.id);
                })
                this.modalShow = true;
            }
            else this.modalShow = false;
        }



        private async close() 
        {
            const res = await this.$confirm(String(this.$t('message.confirmClose')), { buttonTrueText: String(this.$t('message.yes')), buttonFalseText: String(this.$t('message.no')) });
            if (res) { this.modalShow = false }
        }
        
        private submit() {
            
            
                this.$emit('save', this.scenario);
            
            
        }

        get scenariosSorted() {
            const sorted = this.scenarios.sort((a: any, b: any) => {
                return b.isTop - a.isTop;
            });

            var filtered = sorted.filter((a: any) => {
                return a.parentScenarioId != null && a.isTop && this.scenario.scenarioId != a.id;
            });
            return filtered;
        }
       


        
    }
