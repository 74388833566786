

    import { Action, Getter } from 'vuex-class';
    import { Component, Vue, Watch } from 'vue-property-decorator';


    const namespace = 'admin';

    @Component({
        components: {
        },
    })
    export default class AdminUsers extends Vue {

        @Getter('getUsers', { namespace }) getUsers: any;
        @Action('init', { namespace }) init: any;
        @Action('syncLogins', { namespace }) syncLogins: any;
         @Action('fetchUsers', { namespace }) fetchUsers: any;
        @Action('setSelectedUser', { namespace }) setSelectedUser: any;
         @Getter('getIsLoggedIn') getIsLoggedIn: any;
        @Watch('getIsLoggedIn')
        private onGetIsLoggedInChanged() {
            this.fetchUsers();
        }
        mounted()
{
    if (this.getIsLoggedIn)  this.onGetIsLoggedInChanged();
}
        private filter = '';

        

        get filteredUsers() {
            const _self = this;
            const _filteredUsers = this.getUsers.filter(function (user: any) {
                return _self.filter == '' || _self.filter == null || user.title.toLowerCase().includes(_self.filter.toLowerCase());
            });

            return _filteredUsers;
        }

    }

