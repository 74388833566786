
    import { Component, Vue } from 'vue-property-decorator';
    import { Action, Getter } from 'vuex-class';
    

    const namespace = 'main';
    @Component({
        components: {
            
        },
    })
    export default class ModelMenu extends Vue {
        @Getter('getModel', { namespace }) getModel: any;
        @Action('setModel', { namespace }) setModel: any;
        @Getter('getScenarios', { namespace }) getScenarios: any;
        @Getter('getSubModels', { namespace }) getSubModels: any;
        @Getter('getSubModel', { namespace }) getSubModel: any;
        @Action('setSubModel', { namespace }) setSubModel: any;
        @Getter('getModels', { namespace }) getModels: any;
        @Action('downloadFile') downloadFile: any;

        public get isAuthenticated(): boolean {
            if (this.$msal == null) return true;
            return this.$msal.isAuthenticated;
        }

        get models(): any {
            return this.getModels;
        }
        get model(): any {
            const model = this.getModel;
            if (model != null) this.$i18n.locale = model.language;
            return model;
        }
        set model(value: any) {
            this.setModel(value);
        }
        get subModels(): any {
            return this.getSubModels;
        }
        get subModel(): any {
            return this.getSubModel;
        }
        set subModel(value: any) {
            this.setSubModel(value);
        }

        get languages() {
            return [
                { key: 'nl', text: this.$t('message.dutchFormat'), helpUrl: 'https://thebigdatacompany.sharepoint.com/:b:/s/BrancheOmzetModel/Eeg_BD_zaH1ArWdD59Z7awwBnLIgxFJtZ8wyAeDWzC-zHQ?e=4PQV0g' },
                { key: 'en', text: this.$t('message.englishFormat'), helpUrl: 'https://thebigdatacompany.sharepoint.com/:b:/s/BrancheOmzetModel/EQvrt3v6utZFsXKDDoMdbkkBpuSD3R13bVYzZduQIl2xdQ?e=tIkHF2' }
            ]
        }

        get helpUrl() {
            const _self = this;
            let helpUrl = '';
            this.languages.forEach(function (language) {
                if (language.key == _self.$i18n.locale) helpUrl = language.helpUrl;
            })
            return helpUrl;
        }


    }
