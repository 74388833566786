import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from './types';
import axios from 'axios';
import { msalPluginInstance } from "@/plugins/msal";
import createPersistedState from 'vuex-persistedstate';
// @ts-ignore
import Cookies from 'js-cookie';
import { main } from './main/index';
import { admin } from './admin/index';

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
    state: {
        lastUserActionDateTime: Date.now(),
        error: null,
        debug: false,
        busy: 0,
        inProgress: 0,
        user: null,
        databaseStatus: 0,
        fileFormatCulture: 'nl',
        isLoggedIn: false,
        bearerToken: '',
        environment: '',
        version: '',
        notificationText: null
        
    },
    modules: {
        main,
        admin
    },
    plugins: [createPersistedState({
        paths: ['fileFormatCulture'],
        storage: {
            getItem: key => Cookies.get(key),
            setItem: (key: any, value: any ) => Cookies.set(key, value, { expires: 3, secure: true }),
            removeItem: key => Cookies.remove(key)
        }
    })],
    getters: {
        getBusy(state: any): object {
            return state.busy;
        },
        getDebug(state: any): object {
            return state.debug;
        },
        getInProgress(state: any): object {
            return state.inProgress;
        },
        getDatabaseStatus(state: any): object {
            return state.databaseStatus;
        },
        getError(state: any): object {
            return state.error;
        },
        getUser(state: any): object {
            return state.user;
        },
        getLastUserActionDateTime(state: any): object {
            return state.lastUserActionDateTime;
        },
        getLanguages(state: any): object {
            return state.languages;
        },
        getFileFormatCulture(state: any): object {
            return state.fileFormatCulture;
        },
        getIsLoggedIn(state: any): object {
            return state.isLoggedIn;
        },
        getBearerToken(state: any): object {
            return state.bearerToken;
        },
        getEnvironment(state: any): object {
            return state.environment;
        },
        getVersion(state: any): object {
            return state.version;
        },
        getNotificationText(state: any): object {
            return state.notificationText;
        },
        
    },
    mutations: {
        SET_VERSION(state, version) {
            state.version = version
        },
        SET_DEBUG(state, debug) {
            state.debug = debug
        },
        SET_DATABASESTATUS(state, databaseStatus) {
            state.databaseStatus = databaseStatus
        },
        SET_ERROR(state: any, payload: any) {
            if (payload != null) {
                state.busy = 0;
                state.inProgress = 0;
                
                console.error(payload);
            }
            state.error = payload;
        },
        SET_FILEFORMATCULTURE(state: any, payload: any) {
            state.fileFormatCulture = payload;
        },
        SET_USER(state: any, payload: any) {
            state.user = payload;
        },

        SET_STARTBUSY(state: any) {
            if (state.busy == null) {
                state.busy = 0;
            }
            state.busy++;
            
            
        },
        SET_STARTINPROGRESS(state: any) {
            if (state.inProgress == null) {
                state.inProgress = 0;
            }
            state.inProgress++;
            
        },
        SET_ENDBUSY(state: any) {
            if (state.busy == null) {
                state.busy = 0;
            }
            else {
                state.busy--;
            }
            state.lastUserActionDateTime = Date.now();
            
            
        },
        SET_ENDINPROGRESS(state: any) {
            if (state.inProgress == null) {
                state.inProgress = 0;
            }
            else {
                state.inProgress--;
            }
            state.lastUserActionDateTime = Date.now();
            
            
        },
        SET_NOTIFICATIONTEXT(state: any, text: string) {
            state.notificationText = text == null ? null : text;
            setTimeout(() => {
                state.notificationText = null;
            }, 10000); 
        }
        
        
    },
    actions: {
        async loggedIn({ commit, dispatch }): Promise<any> {
            try {
                await dispatch('waitForDatabase')
                const _self = this;
                axios.interceptors.request.use(async function (config: any) {
                    const token = await msalPluginInstance.acquireToken();
                    if (token != null) {
                        config.headers.Authorization = `Bearer ${token}`;
                        config.headers.Accept = 'application/json';
                        _self.state.bearerToken = token as string;
                    }
                    
                    return config;
                }, function (err) {
                    return Promise.reject(err);
                });
                
                this.state.isLoggedIn = true;
            } finally {
            }
        },

        async downloadCsv(context: any, file: any) {
            context.commit('SET_STARTBUSY', null, { root: true });
            return axios.get(file.url).then(async response => {
                //only add the BOM if the content is not empty.
                const blob = response.data == '' ? new Blob([response.data], { type: 'application/pdf' }) : new Blob(['\ufeff' + response.data], { type: 'text/csv' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = file.name;
                link.click();
                URL.revokeObjectURL(link.href);
                context.commit('SET_ENDBUSY', null, { root: true });
            }).catch((error) => {
                context.commit('SET_ERROR', error, { root: true })
            })
        },


      

        async downloadFile(context: any, file: any) {
            
            context.commit('SET_STARTBUSY', null, { root: true });
            return axios.get(file.url, {
                responseType: 'arraybuffer',
                headers: {
                    'Accept': 'application/pdf'
                }
            }).then(async response => {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(new Blob([response.data]));
                link.download = file.name;
                link.click();
                URL.revokeObjectURL(link.href);
                context.commit('SET_ENDBUSY', null, { root: true });
            }).catch((error) => {
                context.commit('SET_ERROR', error, { root: true })
            })
        },

        async waitForDatabase(context: any) {
            context.commit('SET_DATABASESTATUS', 1);
            
            axios.get('/api/system/environment').then(response => {
                this.state.environment = response.data;
            });
            axios.get('/api/system/version').then(response => {
                this.state.version = response.data;
            });
            return axios.get('/api/system/status').then(response => {
                if (response.data == true) {
                    
                    
                        
                    return;
                }
                setTimeout(function () {

                    context.dispatch('waitForDatabase')
                }, 1000)

            }).catch(() => { //error
                // console.error(error);
                setTimeout(function () {

                    context.dispatch('waitForDatabase')
                }, 1000)


            })

        },
    }
};

export default new Vuex.Store(store);


