
    
    import { Action, Getter } from 'vuex-class';
    import { Component, Vue, Watch } from 'vue-property-decorator';


    const namespace = 'admin';

    @Component({
        components: {
        },
    })
    export default class AdminScenarios extends Vue {

        
        @Getter('getScenarioStatus', { namespace }) getScenarioStatus: any;
        @Action('fetchScenarioStatus', { namespace }) fetchScenarioStatus: any;
        @Action('setSelectedScenario', { namespace }) setSelectedScenario: any;
        @Getter('getIsLoggedIn') getIsLoggedIn: any;
    
        @Watch('getIsLoggedIn')
        private onGetIsLoggedInChanged() {
            this.fetchScenarioStatus();
        }
      
        mounted()
        {
            if (this.getIsLoggedIn)  this.onGetIsLoggedInChanged();
        }

        // Computed properties to calculate the totals
        get totalUnprocessed() {
            return this.getScenarioStatus.reduce((sum: number, s: any) => sum + s.unprocessed, 0);
        }

        get totalProcessing() {
            return this.getScenarioStatus.reduce((sum: number, s: any) => sum + s.processing, 0);
        }

        get totalCompleted() {
            return this.getScenarioStatus.reduce((sum: number, s: any) => sum + s.completed, 0);
        }

        get totalFailed() {
            return this.getScenarioStatus.reduce((sum: number, s: any) => sum + s.failed, 0);
        }

        get totalSum() {
            return this.totalUnprocessed + this.totalProcessing + this.totalCompleted + this.totalFailed;
        }

    }
    
