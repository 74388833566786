
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    import dateFilter from '@/filters/date.filter';
    
    @Component
    export default class EditScenario extends Vue {

        private modalShow = false;

        @Prop({ default: null }) public scenario: any;
        @Prop({ default: null }) public originalScenario: any;
        @Prop({ default: null }) public model: any;
        @Prop({ default: null }) public scenarios: any;
        

        @Watch('scenario')
        private onScenarioChanged(value: any) {
            if (value) {
                this.modalShow = true;
            }
            else this.modalShow = false;
        }

        mounted() {
            this.$on('escKeyEditScenario', () => {
                if (this.modalShow) this.close();
            });
            document.addEventListener("keydown", (e) => {
                if (e.keyCode == 27) {
                    this.$emit('escKeyEditScenario');
                }
            });
        }

        private async close() 
        {
            const res = await this.$confirm(String(this.$t('message.confirmClose')), { buttonTrueText: String(this.$t('message.yes')), buttonFalseText: String(this.$t('message.no')) });
            if (res) { this.modalShow = false }
        }

        private submit() {
            // @ts-ignore
            const valid = this.$refs.form.validate()
            if (valid) this.$emit('save', this.scenario);
        }

        private nameRules = [
            // @ts-ignore
            value => !!value || this.$t("message.scenarioNameIsRequired")
        ]

        get scenarioCreated() {
            return dateFilter(this.originalScenario.created, this.$i18n.locale);
        }

        get scenarioModified() {
            return dateFilter(this.originalScenario.modified, this.$i18n.locale);
        }
    }
