
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    

    @Component
    export default class EditLocation extends Vue {


        private modalShow = false;

        @Prop({ default: null }) public location: any;
        @Prop({ default: null }) public model: any;
        @Prop({ default: null }) public scenario: any;

        mounted() {
            this.$on('escKey' + this.$options.name, () => {
                if (this.modalShow) this.close();
            });
            document.addEventListener("keydown", (e) => {
                if (e.keyCode == 27) {
                    this.$emit('escKey' + this.$options.name);
                }
            });
        }

        private valid = false;

        private nameRules: (string)[] = [ (v: string) => !!v || this.$t('message.nameRequired') ];

        private changed() {
            this.$emit('changed', this.location?.locationProperties);
        }
        
        @Watch('location')
        private onLocationChanged(value: any) {
            if (value) this.modalShow = true; else this.modalShow = false;
            if (this.location != null) {

                this.location.locationProperties.forEach(function (locationProperty: any) {
                    if (locationProperty.dataType == 2) {
                        locationProperty["percentageValue"] = locationProperty.numberValue * 100;
                    }
                });
            }
            this.changed();
        }

        private save(scenarioCreation: any) {
            const fieldsToValidate = ['locationname']; // List of your ref names
            this.$refs.form.validate()
            fieldsToValidate.forEach((fieldRef) => {
            const field = this.$refs[fieldRef];
                if (field && field.validate) {
                    const isValid = field.validate();
                    if (!isValid) field.$el.scrollIntoView({ behavior: 'smooth' });
                }
            });
            if (this.valid) {
                this.location['scenarioCreation'] = scenarioCreation;
                this.location.locationProperties.forEach(function (locationProperty: any) {
                    if (locationProperty.dataType == 2) {
                        locationProperty.numberValue = locationProperty.percentageValue / 100;
                    }
                });
                this.$emit('save', this.location );
            }
        }

        private async close() {
            const res = await this.$confirm(String(this.$t('message.confirmClose')), { buttonTrueText: String(this.$t('message.yes')), buttonFalseText: String(this.$t('message.no')) });
            if (res) { this.modalShow = false }
        }

        private menu = false;

        private toggleMenu() {
            this.menu = !this.menu;
        }

        


        groupBy = function(list :any, keyGetter:any) {
        const map = new Map();
            list.forEach((item: any) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }
        


        get locationPropertiesBySection() {
            return this.groupBy(this.location.locationProperties, function (locationProperty: any) { return locationProperty.sectionTitle } );
        }

        get openPanels() {
            let open:any = [0];
            let index = 1;
            this.locationPropertiesBySection.forEach(function (section) {
                if (!section[0].sectionSecondary) open.push(index);
                index++;
            });
            return open;
        }

        set openPanels(value: any) {

        }

        get selectableLocationGroups() {
            const _self = this;
            return this.model.locationGroups.filter(function (locationGroup: any) {
                return locationGroup.selectable || _self.location.locationGroup == locationGroup;
            });
        }
    }
