import { localeIncludes } from 'locale-includes';

export const getters = {
    getModels(state: any): object {
        return state.models;
    },
    getModel(state: any): object {
        return state.model;
    },   
    getSubModels(state: any): object {
        return state.subModels;
    },
    getSubModel(state: any): object {
        return state.subModel;
    },
    getFoundScenarioIds(state: any): object {
        return state.foundScenarioIds;
    },
    getScenarios(state: any): object {
        return state.scenarios;
    },
    getLocationPhoto(state: any): object {
        return state.locationPhoto;
    },
    getScenarioLocations(state: any): object {
        return state.scenarioLocations;
    },
    getAreaSetsVisible(state: any): object {
        return state.areaSetsVisible;
    },
    getPoiSetsVisible(state: any): object {
        return state.poiSetsVisible;
    },
    getHeatMapSetsVisible(state: any): object {
        return state.heatMapSetsVisible;
    },
    getDrivers(state: any): object {
        return state.drivers;
    },
    getLog(state: any): object {
        return state.log;
    },
    getScenario(state: any): object {
        return state.scenario;
    },
    getScenarioDifferences(state: any): object {
        return state.scenarioDifferences;
    },
    getOnlinePois(state: any): object {
        return state.onlinePois;
    },
    isDefaultScenario(state: any): boolean {
        if (state.scenarios == null) return false;
        if (state.scenario == null) return false;
        return (state.scenarios[0].id == state.scenario.id)
    },
    getLocations(state: any): object {
        return state.locations;
    },
    getLocationsNotExcluded(state: any): object {
        return state.locationsNotExcluded;
    },
    getFilteredLocations(state: any): object {
        if (state.filterLocations == null) return state.locationsNotExcluded;
        const fLocations = state.locationsNotExcluded.filter((location: any) => localeIncludes(location.name, state.filterLocations, { usage: "search", sensitivity: "base" }) || (location.locationGroup != null && localeIncludes(location.locationGroup, state.filterLocations, { usage: "search", sensitivity: "base" })));
        return fLocations;
    },
    getMatchingLocations(state: any): object {
        return state.matchingLocations;
    },
    getMatchingScores(state: any): object {
        return state.matchingScores;
    },
    getLocationDrivers(state: any): object {
        return state.locationDrivers;
    },
    getPropertiesChart(state: any): object {
        return state.propertiesChart;
    },
    getLocation(state: any): object {
        return state.location;
    },
    getLocationProperties(state: any): object {
        return state.locationProperties;
    },
    getLocationPropertiesOld(state: any): object {
        return state.locationPropertiesOld;
    },
    getLocationScores(state: any): object {
        return state.locationScores;
    },
    getLocationIsoLines(state: any): object {
        return state.locationIsoLines;
    },
   
    getLocationCatchmentArea(state: any): object {
        return state.locationCatchmentArea;
    },
   
    getArea(state: any): object {
        return state.area;
    },
    getModelParameters(state: any): object {
        return state.modelParameters;
    },
    getScenarioProcessingLog(state: any): object {
        return state.scenarioProcessingLog;
    },
    getNormalizedScoresFunctions(state: any): object {
        return state.normalizedScoresFunctions;
    },
    getLocationEditing(state: any): object {
        return state.locationEditing;
    },
    getLocationClosing(state: any): object {
        return state.locationClosing;
    },
    getScenarioEditing(state: any): object {
        return state.scenarioEditing;
    },
    getScenarioNameEditing(state: any): object {
        return state.scenarioNameEditing;
    },
    getScenarioMerging(state: any): object {
        return state.scenarioMerging;
    },
    getFilterLocations(state: any): any {
        return state.filterLocations;
    },
    getFilterScenarios(state: any): any {
        return state.filterScenarios;
    },
    getStandByFilterLocations(state: any): any {
        return state.standByFilterLocations;
    },
    getStandByFilterScenarios(state: any): any {
        return state.standByFilterScenarios;
    },
    getSearchResults(state: any): any {
        return state.searchResults;
    },
    getMapBounds(state: any): any {
        return state.mapBounds;
    },
    getPois(state: any): any {
        return state.pois;
    },
    getHeatMapPoints(state: any): any {
        return state.heatMapPoints;
    },
    getReadOnly(state: any): any {
        return state.readOnly;
    },
    getMatchingColors(state: any): any {
        return state.matchingColors;
    },
    getIsoLineParameters(state: any): any {
        return state.isoLineParameters;
    },

    getScenarioLocationsVisible(state: any): any {
        return state.scenarioLocationsVisible;
    },
    getIsoLineSet(state: any): any {
        return state.isoLineSet;
    },
    getIsoLineMinutes(state: any): any {
        return state.isoLineMinutes;
    },
    getCatchmentArea(state: any): any {
        return state.catchmentArea;
    }
   
    
};

function isoLine(isoLine: any, arg1: (any: any) => void) {
    throw new Error("Function not implemented.");
}
