

    import { Action, Getter } from 'vuex-class';
    import { Component, Vue, Watch } from 'vue-property-decorator';
    

    const namespace = 'admin';

    @Component({
        components: {
        },
    })
    export default class AdminLogins extends Vue {

        @Getter('getLogins', { namespace }) getLogins: any;
        @Action('deleteLogin', { namespace }) deleteLogin: any;
        @Action('syncLogins', { namespace }) syncLogins: any;
        @Action('fetchLogins', { namespace }) fetchLogins: any;
        @Getter('getIsLoggedIn') getIsLoggedIn: any;
        @Watch('getIsLoggedIn')
        private onGetIsLoggedInChanged() {
            this.fetchLogins();
        }
        mounted() {
            if (this.getIsLoggedIn) this.onGetIsLoggedInChanged();
        }

        private filter = '';

        
        private async confirmRemoveScenario(login: any) {
            const res = await this.$confirm('If you delete this login, the user can not login anymore. This also applies for the other environments. Are you sure you want to delete the login "' + login.displayName + ' (' + login.identity + ')"?', { buttonTrueText: String(this.$t('message.yes')), buttonFalseText: String(this.$t('message.no')), title: String(this.$t('message.warning')) });
            if (res) this.deleteLogin(login.id);
        }

        get filteredLogins() {
            const _self = this;
            const _filteredLogins = this.getLogins.filter(function (login: any) {
                return _self.filter == '' || _self.filter == null || login.displayName.toLowerCase().includes(_self.filter.toLowerCase());
            });

            return _filteredLogins;
        }

    }

